.container {
	width: 90%;
	margin: 0 auto;
	padding: 1em;
}

.hr {
	display: flex;
	align-items: center;
	margin-bottom: 1.5em;
}

.hr div:first-child {
	width: 15%;
	height: 1px;
	background-color: #fff;
}
.hr div:last-child {
	width: 57%;
	height: 1px;
	background-color: #fff;
}

.hr h3 {
	margin: 0 0.4em 0 0.4em;
	width: 25%;
	text-align: center;
	font-size: 1rem;
}

.form {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: left;
}

.form div {
	width: 100%;
	padding: 0.3em;
	font-size: 0.9rem;
}

.form h6 {
	padding-left: 1em;
	color: tomato;
}

.form input,
.form textarea {
	padding: 0.7em;
	width: 100%;
	border-radius: 6px;
}

.form input:focus,
.form textarea:focus {
	outline: 2px solid rgba(80, 191, 230, 0.7);
}

.form input[type="submit"] {
	width: 30%;
	margin-top: 10px;
	border: none;
	padding: 1em 2em;
	background-color: rgba(46, 199, 255, 0.5);
	color: #fff;
	transition: all 0.5s ease;
	cursor: pointer;
}
.form input[type="submit"]:hover {
	background-color: rgba(0, 187, 255, 0.5);
	transform: translateY(3px);
}
.form input[type="submit"]:active {
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.form input[type="submit"]:disabled {
	background-color: grey;
	color: black;
	transform: none;
}

.recaptcha {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100px;
	margin: 0 auto;
}

@media (min-width: 555px) {
	.hr {
		display: none;
	}
}
@media (min-width: 700px) {
	.form {
		width: 80%;
		margin: 0 auto;
	}
}

@media (min-width: 900px) {
	.form {
		width: 70%;
	}
	.form div:nth-child(2),
	.form div:nth-child(4) {
		width: 60%;
		align-self: flex-start;
	}
}

@media (min-width: 1200px) {
	.form {
		width: 60%;
	}
}
@media (min-width: 1500px) {
	.form {
		width: 50%;
	}
	.form div:nth-child(2),
	.form div:nth-child(4) {
		width: 50%;
		align-self: flex-start;
	}
}
