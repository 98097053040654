.nav {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 10vh;
	margin: 0 auto;
	background-color: transparent;
}

.nav div + div {
	margin-left: 40px;
	transition: all 1s ease;
}

.nav div {
	position: relative;
}

.nav div.active::after {
	content: "";
	position: absolute;
	width: 110%;
	height: 1px;
	border-radius: 16px;
	background-color: #fff;
	top: 28px;
	left: 0;
	/* left: 55%; */
	/* transform: translate(50%, 50%); */
	/* -webkit-box-shadow: 0px 0px 50px 20px rgba(107, 215, 255, 0.37);
	box-shadow: 0px 0px 50px 20px rgba(80, 191, 230, 0.37); */
}

.nav a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8rem;
}

.nav a div {
	display: none;
	padding-left: 3px;
}

/* Media Queries */
@media (min-width: 555px) {
	.nav a div {
		display: block;
	}
	.nav div + div {
		margin-left: 30px;
	}
}
