* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Oxygen", sans-serif;
	/* background: url("https://cdn.dribbble.com/users/32512/screenshots/3865296/trapcode_map_by_gleb.gif") no-repeat center center fixed; */
	background-color: #333;
	background-size: cover;
	color: #fff;
	position: relative;
	min-height: 100vh;
	overflow-x: hidden;
	user-select: none;
}
body::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
	text-decoration: none;
	transition: box-shadow 0.5s ease;
}
a:hover {
	-webkit-box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.7);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

a:active {
	-webkit-box-shadow: 0px 0px 15px 8px rgba(255, 255, 255, 0.5);
	box-shadow: 0px 0px 15px 8px rgb(255, 255, 255, 0.5);
}

i {
	display: block;
}

img {
	display: block;
	max-width: 100%;
}

.center {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}
