.modal {
	position: absolute;
	inset: 0;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	transition: display 0.5s ease-in-out;
}

.open {
	display: block;
}

.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.content {
	width: 85%;
	height: 300px;
	margin-top: 13em;
	border: 2px solid rgba(80, 191, 230, 0.5);
	background-color: rgb(214, 214, 214);
	border-radius: 10px;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 1em;
	line-height: 1.5;
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
	text-align: center;
}

.content h1 {
	font-size: 3.5rem;
}

.content span {
	color: rgb(67, 205, 255);
}

.content button {
	width: 30%;
	margin-top: 10px;
	border: none;
	padding: 1em 2em;
	background-color: rgba(46, 199, 255, 0.5);
	color: #fff;
	transition: all 0.5s ease;
	cursor: pointer;
	text-align: center;
}
.content button:hover {
	background-color: rgba(0, 187, 255, 0.5);
	transform: translateY(3px);
}

.content button:active {
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

@media (min-width: 700px) {
	.content {
		width: 65%;
	}
}
@media (min-width: 900px) {
	.content {
		width: 60%;
	}
	.content a {
		width: 25%;
	}
}
@media (min-width: 1200px) {
	.content {
		width: 40%;
	}
}
@media (min-width: 1500px) {
	.content {
		width: 30%;
	}
	.content a {
		width: 30%;
	}
}
