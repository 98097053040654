.container {
	width: 90%;
	margin: 0 auto;
	padding: 1em;
}

.hr {
	display: flex;
	align-items: center;
	margin-bottom: 1.5em;
}

.hr div:first-child {
	width: 15%;
	height: 1px;
	background-color: #fff;
}
.hr div:last-child {
	width: 57%;
	height: 1px;
	background-color: #fff;
}

.hr h3 {
	margin: 0 0.4em 0 0.4em;
	width: 25%;
	text-align: center;
	font-size: 1rem;
}
@media (min-width: 555px) {
	.hr {
		display: none;
	}
}

.parent {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 30px;
}

.parent div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.parent div div {
	position: relative;
	overflow: hidden;
}

.parent div div .content {
	position: absolute;
	bottom: 0px;
	background-color: rgba(0, 174, 255, 0.5);
	width: 100%;
	height: 0;
	transition: height 0.5s ease-in-out;
	display: flex;
	justify-content: space-evenly;
	color: black;
	font-size: 1.3rem;
}

.parent div div:hover .content {
	height: 20%;
}

.div1 {
	grid-area: 1 / 1 / 2 / 2;
}
.div2 {
	grid-area: 2 / 1 / 3 / 2;
}
.div3 {
	grid-area: 3 / 1 / 4 / 2;
}

.spinner {
	height: 110px;
	width: 100%;
	margin-bottom: 0.5em;
}

@media (min-width: 700px) {
	.parent {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: px;
		min-height: 50vh;
	}

	.div1 {
		grid-area: 1 / 1 / 2 / 2;
	}
	.div2 {
		grid-area: 1 / 2 / 2 / 3;
	}
	.div3 {
		grid-area: 2 / 1 / 3 / 2;
	}
}
@media (min-width: 1200px) {
	.parent {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 30px;
		grid-row-gap: px;
	}

	.div1 {
		grid-area: 1 / 1 / 2 / 2;
	}
	.div2 {
		grid-area: 1 / 2 / 2 / 3;
	}
	.div3 {
		grid-area: 1 / 3 / 2 / 4;
	}
}
