@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.parent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80%;
	height: 90vh;
	margin: 0 auto;
	padding: 1.5em 0 1em 0;
}

.grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(7, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	padding: 10px;
	font-size: 3rem;
	border-bottom: 1px ridge white;
	border-top: 1px ridge white;
	border-radius: 0px 14px 0px 14px;
	grid-area: 1 / 1 / 2 / 2;
	font-family: "Satisfy", cursive;
}

.grid div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.div1 {
	grid-area: 1 / 1 / 2 / 2;
	transition: all 2s ease;
}

.div1:hover {
	transform: rotate(-360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.div2 {
	grid-area: 2 / 2 / 3 / 3;
	transition: all 2s ease;
}

.div2:hover {
	transform: scale(0.1, 0.1);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.div3 {
	grid-area: 3 / 2 / 4 / 3;
	transition: all 2s ease;
}

.div3:hover {
	transform: matrix(1, -0.3, 0, 1, 0, 0);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.div4 {
	grid-area: 4 / 3 / 5 / 4;
	transition: all 4s ease;
}

.div4:hover {
	transform: skew(180deg, 180deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}
.div5 {
	grid-area: 5 / 4 / 6 / 5;
	transition: all 2s ease;
}

.div5:hover {
	transform: rotateX(360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.div6 {
	grid-area: 6 / 4 / 7 / 5;
	transition: all 2s ease;
}

.div6:hover {
	transform: rotateZ(360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.div7 {
	grid-area: 7 / 5 / 8 / 6;
	transition: all 2s ease;
}

.div7:hover {
	transform: rotate3d(1, 1, 1, 360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.bio {
	display: flex;
	flex-direction: column;
	font-size: 0.9rem;
	grid-area: 1 / 2 / 2 / 3;
	align-items: center;
	padding-top: 20px;
	text-align: center;
}

.bio div {
	line-height: 1.5;
}
.bio div:nth-child(1) {
	color: rgb(216, 216, 216);
}
.bio div:nth-child(2) {
	color: rgb(255, 255, 255);
	font-weight: 700;
}
.bio div:nth-child(3) {
	color: rgb(121, 121, 121);
}

.social {
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin-top: 30px; */
	margin-bottom: 10px;
	font-size: 2.5rem;
}
.social div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social div + div {
	padding-left: 10px;
}

/* Media Queries */
@media (min-width: 555px) {
	.parent {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: row;
		height: 90vh;

		width: 90%;
		margin: 0 auto;
	}
	.grid {
		width: 50%;
	}

	.bio {
		padding: 0;
		width: 50%;
		justify-content: center;
	}
}

@media (min-width: 1000px) {
	.parent {
		width: 95%;
		justify-content: space-between;
	}
	.grid {
		width: 46%;
	}

	.bio {
		width: 46%;
		font-size: 1.1rem;
	}
	.social {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/* margin-top: 30px; */

		font-size: 2rem;
	}
}

@media (min-width: 1500px) {
	.parent {
		padding: 2em 0 3em 9em;
	}
}
