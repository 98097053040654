@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Oxygen", sans-serif;
	/* background: url("https://cdn.dribbble.com/users/32512/screenshots/3865296/trapcode_map_by_gleb.gif") no-repeat center center fixed; */
	background-color: #333;
	background-size: cover;
	color: #fff;
	position: relative;
	min-height: 100vh;
	overflow-x: hidden;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
body::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
	text-decoration: none;
	transition: box-shadow 0.5s ease;
}
a:hover {
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

a:active {
	box-shadow: 0px 0px 15px 8px rgb(255, 255, 255, 0.5);
}

i {
	display: block;
}

img {
	display: block;
	max-width: 100%;
}

.center {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.About_container__3yndN {
	width: 90%;
	margin: 0 auto;
	padding: 1em;
}
.About_hr__2ckZr {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 1.5em;
}

.About_hr__2ckZr div:first-child {
	width: 15%;
	height: 1px;
	background-color: #fff;
}
.About_hr__2ckZr div:last-child {
	width: 57%;
	height: 1px;
	background-color: #fff;
}

.About_hr__2ckZr h3 {
	margin: 0 0.4em 0 0.4em;
	width: 25%;
	text-align: center;
	font-size: 1rem;
}
@media (min-width: 555px) {
	.About_hr__2ckZr {
		display: none;
	}
}

.About_about__2P5xE {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-align-items: center;
	        align-items: center;
}

.About_img__30vUu {
	padding: 1em;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.About_img__30vUu img {
	-webkit-clip-path: ellipse(300px 280px at 30% 20%);
	        clip-path: ellipse(300px 280px at 30% 20%);
}

.About_p__1010d {
	line-height: 1.3;
	padding: 1.5em;
	text-align: center;
}

@media (min-width: 555px) {
	img {
		width: 400px;
	}

	.About_img__30vUu img {
		-webkit-clip-path: ellipse(380px 300px at 30% 20%);
		        clip-path: ellipse(380px 300px at 30% 20%);
	}
}

.ContactMe_container__3iSb- {
	width: 90%;
	margin: 0 auto;
	padding: 1em;
}

.ContactMe_hr__3gR1J {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 1.5em;
}

.ContactMe_hr__3gR1J div:first-child {
	width: 15%;
	height: 1px;
	background-color: #fff;
}
.ContactMe_hr__3gR1J div:last-child {
	width: 57%;
	height: 1px;
	background-color: #fff;
}

.ContactMe_hr__3gR1J h3 {
	margin: 0 0.4em 0 0.4em;
	width: 25%;
	text-align: center;
	font-size: 1rem;
}

.ContactMe_form__3WQcz {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: left;
}

.ContactMe_form__3WQcz div {
	width: 100%;
	padding: 0.3em;
	font-size: 0.9rem;
}

.ContactMe_form__3WQcz h6 {
	padding-left: 1em;
	color: tomato;
}

.ContactMe_form__3WQcz input,
.ContactMe_form__3WQcz textarea {
	padding: 0.7em;
	width: 100%;
	border-radius: 6px;
}

.ContactMe_form__3WQcz input:focus,
.ContactMe_form__3WQcz textarea:focus {
	outline: 2px solid rgba(80, 191, 230, 0.7);
}

.ContactMe_form__3WQcz input[type="submit"] {
	width: 30%;
	margin-top: 10px;
	border: none;
	padding: 1em 2em;
	background-color: rgba(46, 199, 255, 0.5);
	color: #fff;
	transition: all 0.5s ease;
	cursor: pointer;
}
.ContactMe_form__3WQcz input[type="submit"]:hover {
	background-color: rgba(0, 187, 255, 0.5);
	-webkit-transform: translateY(3px);
	        transform: translateY(3px);
}
.ContactMe_form__3WQcz input[type="submit"]:active {
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.ContactMe_form__3WQcz input[type="submit"]:disabled {
	background-color: grey;
	color: black;
	-webkit-transform: none;
	        transform: none;
}

.ContactMe_recaptcha__CKoEO {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	width: 100px;
	margin: 0 auto;
}

@media (min-width: 555px) {
	.ContactMe_hr__3gR1J {
		display: none;
	}
}
@media (min-width: 700px) {
	.ContactMe_form__3WQcz {
		width: 80%;
		margin: 0 auto;
	}
}

@media (min-width: 900px) {
	.ContactMe_form__3WQcz {
		width: 70%;
	}
	.ContactMe_form__3WQcz div:nth-child(2),
	.ContactMe_form__3WQcz div:nth-child(4) {
		width: 60%;
		-webkit-align-self: flex-start;
		        align-self: flex-start;
	}
}

@media (min-width: 1200px) {
	.ContactMe_form__3WQcz {
		width: 60%;
	}
}
@media (min-width: 1500px) {
	.ContactMe_form__3WQcz {
		width: 50%;
	}
	.ContactMe_form__3WQcz div:nth-child(2),
	.ContactMe_form__3WQcz div:nth-child(4) {
		width: 50%;
		-webkit-align-self: flex-start;
		        align-self: flex-start;
	}
}

.Spinner_center__17MRX {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: transparent;
}
.Spinner_wave__tO23w {
	width: 5px;
	height: 100px;
	background: linear-gradient(45deg, cyan, #fff);
	margin: 10px;
	-webkit-animation: Spinner_wave__tO23w 1s linear infinite;
	        animation: Spinner_wave__tO23w 1s linear infinite;
	border-radius: 20px;
}
.Spinner_wave__tO23w:nth-child(2) {
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
}
.Spinner_wave__tO23w:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
.Spinner_wave__tO23w:nth-child(4) {
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}
.Spinner_wave__tO23w:nth-child(5) {
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
}
.Spinner_wave__tO23w:nth-child(6) {
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
}
.Spinner_wave__tO23w:nth-child(7) {
	-webkit-animation-delay: 0.6s;
	        animation-delay: 0.6s;
}
.Spinner_wave__tO23w:nth-child(8) {
	-webkit-animation-delay: 0.7s;
	        animation-delay: 0.7s;
}
.Spinner_wave__tO23w:nth-child(9) {
	-webkit-animation-delay: 0.8s;
	        animation-delay: 0.8s;
}
.Spinner_wave__tO23w:nth-child(10) {
	-webkit-animation-delay: 0.9s;
	        animation-delay: 0.9s;
}

@-webkit-keyframes Spinner_wave__tO23w {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@keyframes Spinner_wave__tO23w {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

.Modal_modal__1QctD {
	position: absolute;
	inset: 0;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	transition: display 0.5s ease-in-out;
}

.Modal_open__19sDd {
	display: block;
}

.Modal_wrapper__2rdba {
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: center;
	        justify-content: center;
}
.Modal_content__2EI7J {
	width: 85%;
	height: 300px;
	margin-top: 13em;
	border: 2px solid rgba(80, 191, 230, 0.5);
	background-color: rgb(214, 214, 214);
	border-radius: 10px;
	color: black;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	-webkit-align-items: center;
	        align-items: center;
	padding: 1em;
	line-height: 1.5;
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
	text-align: center;
}

.Modal_content__2EI7J h1 {
	font-size: 3.5rem;
}

.Modal_content__2EI7J span {
	color: rgb(67, 205, 255);
}

.Modal_content__2EI7J button {
	width: 30%;
	margin-top: 10px;
	border: none;
	padding: 1em 2em;
	background-color: rgba(46, 199, 255, 0.5);
	color: #fff;
	transition: all 0.5s ease;
	cursor: pointer;
	text-align: center;
}
.Modal_content__2EI7J button:hover {
	background-color: rgba(0, 187, 255, 0.5);
	-webkit-transform: translateY(3px);
	        transform: translateY(3px);
}

.Modal_content__2EI7J button:active {
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

@media (min-width: 700px) {
	.Modal_content__2EI7J {
		width: 65%;
	}
}
@media (min-width: 900px) {
	.Modal_content__2EI7J {
		width: 60%;
	}
	.Modal_content__2EI7J a {
		width: 25%;
	}
}
@media (min-width: 1200px) {
	.Modal_content__2EI7J {
		width: 40%;
	}
}
@media (min-width: 1500px) {
	.Modal_content__2EI7J {
		width: 30%;
	}
	.Modal_content__2EI7J a {
		width: 30%;
	}
}

.Hero_parent__1-WBp {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 80%;
	height: 90vh;
	margin: 0 auto;
	padding: 1.5em 0 1em 0;
}

.Hero_grid__2gUL3 {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(7, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	padding: 10px;
	font-size: 3rem;
	border-bottom: 1px ridge white;
	border-top: 1px ridge white;
	border-radius: 0px 14px 0px 14px;
	grid-area: 1 / 1 / 2 / 2;
	font-family: "Satisfy", cursive;
}

.Hero_grid__2gUL3 div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.Hero_div1__vs9Or {
	grid-area: 1 / 1 / 2 / 2;
	transition: all 2s ease;
}

.Hero_div1__vs9Or:hover {
	-webkit-transform: rotate(-360deg);
	        transform: rotate(-360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_div2__ZGFbI {
	grid-area: 2 / 2 / 3 / 3;
	transition: all 2s ease;
}

.Hero_div2__ZGFbI:hover {
	-webkit-transform: scale(0.1, 0.1);
	        transform: scale(0.1, 0.1);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_div3__1TwUX {
	grid-area: 3 / 2 / 4 / 3;
	transition: all 2s ease;
}

.Hero_div3__1TwUX:hover {
	-webkit-transform: matrix(1, -0.3, 0, 1, 0, 0);
	        transform: matrix(1, -0.3, 0, 1, 0, 0);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_div4__2-GAt {
	grid-area: 4 / 3 / 5 / 4;
	transition: all 4s ease;
}

.Hero_div4__2-GAt:hover {
	-webkit-transform: skew(180deg, 180deg);
	        transform: skew(180deg, 180deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}
.Hero_div5__3V4gK {
	grid-area: 5 / 4 / 6 / 5;
	transition: all 2s ease;
}

.Hero_div5__3V4gK:hover {
	-webkit-transform: rotateX(360deg);
	        transform: rotateX(360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_div6__2pu6k {
	grid-area: 6 / 4 / 7 / 5;
	transition: all 2s ease;
}

.Hero_div6__2pu6k:hover {
	-webkit-transform: rotateZ(360deg);
	        transform: rotateZ(360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_div7__1-ynn {
	grid-area: 7 / 5 / 8 / 6;
	transition: all 2s ease;
}

.Hero_div7__1-ynn:hover {
	-webkit-transform: rotate3d(1, 1, 1, 360deg);
	        transform: rotate3d(1, 1, 1, 360deg);
	box-shadow: 0px 0px 50px 8px rgba(80, 191, 230, 0.5);
}

.Hero_bio__3cDit {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	font-size: 0.9rem;
	grid-area: 1 / 2 / 2 / 3;
	-webkit-align-items: center;
	        align-items: center;
	padding-top: 20px;
	text-align: center;
}

.Hero_bio__3cDit div {
	line-height: 1.5;
}
.Hero_bio__3cDit div:nth-child(1) {
	color: rgb(216, 216, 216);
}
.Hero_bio__3cDit div:nth-child(2) {
	color: rgb(255, 255, 255);
	font-weight: 700;
}
.Hero_bio__3cDit div:nth-child(3) {
	color: rgb(121, 121, 121);
}

.Hero_social__YYZgk {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	/* margin-top: 30px; */
	margin-bottom: 10px;
	font-size: 2.5rem;
}
.Hero_social__YYZgk div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.Hero_social__YYZgk div + div {
	padding-left: 10px;
}

/* Media Queries */
@media (min-width: 555px) {
	.Hero_parent__1-WBp {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-flex-direction: row;
		        flex-direction: row;
		height: 90vh;

		width: 90%;
		margin: 0 auto;
	}
	.Hero_grid__2gUL3 {
		width: 50%;
	}

	.Hero_bio__3cDit {
		padding: 0;
		width: 50%;
		-webkit-justify-content: center;
		        justify-content: center;
	}
}

@media (min-width: 1000px) {
	.Hero_parent__1-WBp {
		width: 95%;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.Hero_grid__2gUL3 {
		width: 46%;
	}

	.Hero_bio__3cDit {
		width: 46%;
		font-size: 1.1rem;
	}
	.Hero_social__YYZgk {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		/* margin-top: 30px; */

		font-size: 2rem;
	}
}

@media (min-width: 1500px) {
	.Hero_parent__1-WBp {
		padding: 2em 0 3em 9em;
	}
}

.Nav_nav__KViWQ {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 80%;
	height: 10vh;
	margin: 0 auto;
	background-color: transparent;
}

.Nav_nav__KViWQ div + div {
	margin-left: 40px;
	transition: all 1s ease;
}

.Nav_nav__KViWQ div {
	position: relative;
}

.Nav_nav__KViWQ div.Nav_active__2puts::after {
	content: "";
	position: absolute;
	width: 110%;
	height: 1px;
	border-radius: 16px;
	background-color: #fff;
	top: 28px;
	left: 0;
	/* left: 55%; */
	/* transform: translate(50%, 50%); */
	/* -webkit-box-shadow: 0px 0px 50px 20px rgba(107, 215, 255, 0.37);
	box-shadow: 0px 0px 50px 20px rgba(80, 191, 230, 0.37); */
}

.Nav_nav__KViWQ a {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 0.8rem;
}

.Nav_nav__KViWQ a div {
	display: none;
	padding-left: 3px;
}

/* Media Queries */
@media (min-width: 555px) {
	.Nav_nav__KViWQ a div {
		display: block;
	}
	.Nav_nav__KViWQ div + div {
		margin-left: 30px;
	}
}

.Works_container__3sNHf {
	width: 90%;
	margin: 0 auto;
	padding: 1em;
}

.Works_hr__2D-yP {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 1.5em;
}

.Works_hr__2D-yP div:first-child {
	width: 15%;
	height: 1px;
	background-color: #fff;
}
.Works_hr__2D-yP div:last-child {
	width: 57%;
	height: 1px;
	background-color: #fff;
}

.Works_hr__2D-yP h3 {
	margin: 0 0.4em 0 0.4em;
	width: 25%;
	text-align: center;
	font-size: 1rem;
}
@media (min-width: 555px) {
	.Works_hr__2D-yP {
		display: none;
	}
}

.Works_parent__2OJQu {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 30px;
}

.Works_parent__2OJQu div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.Works_parent__2OJQu div div {
	position: relative;
	overflow: hidden;
}

.Works_parent__2OJQu div div .Works_content__RLb57 {
	position: absolute;
	bottom: 0px;
	background-color: rgba(0, 174, 255, 0.5);
	width: 100%;
	height: 0;
	transition: height 0.5s ease-in-out;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	color: black;
	font-size: 1.3rem;
}

.Works_parent__2OJQu div div:hover .Works_content__RLb57 {
	height: 20%;
}

.Works_div1__3h5Fl {
	grid-area: 1 / 1 / 2 / 2;
}
.Works_div2__3NIVN {
	grid-area: 2 / 1 / 3 / 2;
}
.Works_div3__12oB- {
	grid-area: 3 / 1 / 4 / 2;
}

.Works_spinner__2pIgY {
	height: 110px;
	width: 100%;
	margin-bottom: 0.5em;
}

@media (min-width: 700px) {
	.Works_parent__2OJQu {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-row-gap: px;
		min-height: 50vh;
	}

	.Works_div1__3h5Fl {
		grid-area: 1 / 1 / 2 / 2;
	}
	.Works_div2__3NIVN {
		grid-area: 1 / 2 / 2 / 3;
	}
	.Works_div3__12oB- {
		grid-area: 2 / 1 / 3 / 2;
	}
}
@media (min-width: 1200px) {
	.Works_parent__2OJQu {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 30px;
		grid-row-gap: px;
	}

	.Works_div1__3h5Fl {
		grid-area: 1 / 1 / 2 / 2;
	}
	.Works_div2__3NIVN {
		grid-area: 1 / 2 / 2 / 3;
	}
	.Works_div3__12oB- {
		grid-area: 1 / 3 / 2 / 4;
	}
}

